
import Joi from 'joi';
import { useRouter } from 'vue-router';
import { defineComponent, ref } from 'vue';
import {
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  IonLabel,
  IonFooter,
  IonItem,
} from '@ionic/vue';
import DlvrInput from '@/components/DlvrInput.vue';
import { validateFields } from '@/lib/forms';
import { loading, openToast } from '@/composables/ionicControllers';

import { chevronBackOutline } from 'ionicons/icons';

import { auth } from '@/store';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    DlvrInput,
    IonButton,
    IonIcon,
    IonLabel,
    IonFooter,
    IonItem,
  },
  setup() {
    const { forgotPassword } = auth;

    const identifier = ref('');

    const router = useRouter();

    const validationRules = {
      'Email or phone number': Joi.string()
        .min(5)
        .required(),
    };

    const schema = Joi.object(validationRules);

    const onSubmit = async () => {
      const { error, value } = validateFields(
        schema,
        () => ({
          'Email or phone number': identifier.value,
        }),
        false,
      );

      if (error) {
        console.log('error: ', error);
      } else {
        const loader = await loading();
        await loader.present();

        const response: any = await forgotPassword(
          value['Email or phone number'],
        );

        if (!response.ok) {
          await openToast(response?.message);
          await loader.dismiss();
        } else {
          await loader.dismiss();
          await openToast(
            response?.message || 'Email sent Successful',
          );
        }

        await loader.dismiss();
      }
    };
    return {
      chevronBackOutline,
      validationRules,
      router,
      identifier,
      onSubmit,
    };
  },
});
